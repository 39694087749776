import React from 'react'
import { graphql } from 'gatsby';
import styled from 'styled-components'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Layout from '../components/Layout'
import Header from '../components/Header'
import FeaturedPost from '../components/FeaturedPost'
import Container from '../elements/Container'
import Button from '../elements/Button'

const IntroWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
`

const Column = styled.div`
  position: relative;
  z-index: 100;
  transition: ${props => props.theme.transitions.boom.transition};
  height: 15rem;
  flex-basis: calc(99.9% * 1 / 2 - 1rem);
  max-width: calc(99.9% * 1 / 2 - 1rem);
  width: calc(99.9% * 1 / 2 - 1rem);
  padding-top: 70px;
  @media (max-width: 800px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    height: 18rem;
    padding-top: 30px;
    &:first-child {
      margin-bottom: 2rem;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: 16rem;
    padding-top: 0px;
  }
`

const Image = styled.div`
  position: absolute;
  top: 0;
  overflow: hidden;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  object-fit: cover;
  border-radius: ${props => props.theme.borderRadius.default};
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  > div {
    position: static !important;
  }
  > div > div {
    position: static !important;
  }
`

const PostsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 2rem;
  a {
    text-decoration: none;
  }
`

const Title = styled.div`
  text-align: center;
  font-family: ${props => props.theme.fontFamily.intro};
  font-weight: 300;
  font-size: 2.0rem;
  line-height: 2.5rem;
  max-width: 860px;
  margin: 3rem auto;
  text-shadow: ${props => props.theme.shadow.text.big};
`

const Text = styled.div`
  text-align: center;
  font-family: ${props => props.theme.fontFamily.intro};
  font-weight: 200;
  font-size: 1.1rem;
  line-height: 1.8rem;
  max-width: 860px;
  margin: auto;
  text-shadow: ${props => props.theme.shadow.text.big};
`

export const IndexPageTemplate = ({
  title,
  subtitle,
  titleimage,
  intro,
  subintro,
  aboutus,
  aboutustext,
  aboutusimage,
  posts
}) => (
  <div>
    <Header big={true} title='"Happiness is making the impossible possible"' subtitle='Die Reiseamseln' imageUrl='https://images.prismic.io/reiseamseln/25e845ae-476f-4c70-a97f-99deb405763f_cover1.jpg?auto=compress,format'/>
    <Container>
      <Title>
        DER ALLTAG IST NICHT GENUG
      </Title>
      <Text>
        Oft sehnten wir uns danach, mehr von der Welt zu sehen und zu erleben. Wir entschieden uns dazu, uns ein ganzes Jahr zu nehmen. Ein Jahr ohne Alltag. Ein Jahr ohne Verpflichtung. Ein Jahr, um die Welt zu bereisen und ein bisschen besser kennenzulernen.
      </Text>
    </Container>

    <Container type="big">
      <Title>
        ÜBER UNS
      </Title>
      <IntroWrapper>
        <Column>
          <Text>
            Nach einer intensiven Kennenlern-Phase entschieden wir uns dazu, gemeinsam durchs Leben zu gehen und gaben uns im Oktober 2019 das Ja-Wort.
            Seit Januar 2020 sind wir auf einer ungewissen und spannenden Reise um die Welt und wollen unsere Eindrücke und Erfahrungen an jeden weitergeben, der daran teilhaben will.
          </Text>
        </Column> 
        <Column data-aos="fade-right">
          <Image style={{
            backgroundImage: `url(${
              aboutusimage.url
            })`,
          }}>>
          </Image>            
        </Column>
      </IntroWrapper>
    </Container>

    <Container>
      <Title>
        Neue Blogeinträge
      </Title>
      <PostsWrapper>
        {posts.map((post, index) => (
          <FeaturedPost
            delay={index}
            post={ post.node }
            key={ post.node._meta.id }
          />
        ))}
        <Button to="/blogroll" type="primary" role="button">
          Mehr
        </Button>
      </PostsWrapper>
    </Container>
  </div>
)

export default class IndexPage extends React.Component {
  componentDidMount(){
    AOS.init({
      duration : 2000
    })
  }
  
  render() {
    // Required check for no data being returned
    const doc = this.props.data.prismic.allHomepages.edges.slice(0,1).pop();
    const posts = this.props.data.prismic.allPosts.edges.slice(0,2);
    if (!doc) return null;

    return (
      <Layout>
        <IndexPageTemplate
          title={doc.node.title}
          subtitle={doc.node.subtitle}
          titleimage={doc.node.titleimage}
          intro={doc.node.intro}
          subintro={doc.node.subintro}
          aboutus={doc.node.aboutus}
          aboutustext={doc.node.aboutustext}
          aboutusimage={doc.node.aboutusimage}
          posts={posts}
        />
      </Layout>
    )
  }
}

export const query = graphql`
{
  prismic{
    allHomepages {
      edges {
        node {
          title
          subtitle
          titleimage
          intro
          subintro
          aboutus
          aboutustext
          aboutusimage
        }
      }
    }
    allPosts(sortBy: date_DESC){
      edges{
        node{
          _meta{
            id
            uid
            type
          }
          title
          date
          timespan
          emojis
          titleImage
        }
      }
    }
  }
}
`
